import React from "react";
import { Switch, Route } from "react-router-dom";
import { Pages } from "./pages";
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import OrdinaryRoute from './Utils/OrdinaryRoute';


export type ConfirmRegistrationUrlParams = {
    idMembers: string,
    secureCode: string
}

export type FinishRegistrationUrlParams = {
    idMembers: string,
    secureCode: string
}

export type UpgradeOfferDetailUrlParams = {
    id: string
}

export type CompetitionUrlParams = {
    id: string
}

export type OnlineActionsUrlParams = {
    id: string
}

export type OnlineDataUrlParams = {
    actionId: string,
    dancerId: string
}

export type ApplicationUrlParams = {
    id: string,
    idCompetitions: string,
    secureCode: string
}

export type ApplicationListByCompetitionsUrlParams = {
    idCompetitions: string
    
}

export type ApplicationListByCompetitionsMasterUrlParams = {
    idCompetitions: string
    password: string
}

export type ApplicationListByPeopleUrlParams = {
    idCompetitions: string
    password: string
}

export const ROUTES = {
    /* ACCOUNTS */
    login: {
        route: "/login",
        getLink: () => {
            return ROUTES.login.route
        } 
    },
    logout: {
        route: "/logout",
        getLink: () => {
            return ROUTES.logout.route
        } 
    },  
    myAccount: {
        route: "/my-account",
        getLink: () => {
            return ROUTES.myAccount.route
        } 
    },     

    registration: {
        route: "/registration",
        getLink: () => {
            return ROUTES.registration.route
        } 
    }, 
    
    calStartPage: {
        route: "/cal",
        getLink: () => {
            return ROUTES.calStartPage.route
        } 
    },  

    registrationCAL: {
        route: "/registration-cal",
        getLink: () => {
            return ROUTES.registrationCAL.route
        } 
    },  

    registrationFinishCAL: {
        route: "/registration-cal-finish/:idMembers/:secureCode",
        getLink: (idMembers: string, secureCode: string) => {
            return ROUTES.registrationFinishCAL.route.replace(":idMembers", idMembers.toString()).replace(":secureCode", secureCode.toString())
        } 
    }, 

    memberInfoCAL: {
        route: "/member-info-cal",
        getLink: () => {
            return ROUTES.memberInfoCAL.route
        } 
    },  

    memberMedicalExaminationLoadCAL: {
        route: "/member-medical-cal",
        getLink: () => {
            return ROUTES.memberMedicalExaminationLoadCAL.route
        } 
    },  

    memberInfoCPADU: {
        route: "/member-info-cpadu",
        getLink: () => {
            return ROUTES.memberInfoCPADU.route
        } 
    },  

    confirmRegistration: {
        route: "/confirm-registration/:idMembers/:secureCode",
        getLink: (idMembers: string, secureCode: string) => {
            return ROUTES.confirmRegistration.route.replace(":idMembers", idMembers.toString()).replace(":secureCode", secureCode.toString())
        } 
    },

    confirmRegistrationCAL: {
        route: "/confirm-registration-cal/:idMembers/:secureCode",
        getLink: (idMembers: string, secureCode: string) => {
            return ROUTES.confirmRegistrationCAL.route.replace(":idMembers", idMembers.toString()).replace(":secureCode", secureCode.toString())
        } 
    },


    dashboard: {
        route: "/dashboard",
        getLink: () => {
            return ROUTES.dashboard.route
        } 
    },

    members: {
        route: "/members",
        getLink: () => {
            return ROUTES.members.route
        } 
    },

    membersCAL: {
        route: "/members-cal",
        getLink: () => {
            return ROUTES.membersCAL.route
        } 
    },

    bankAccountTransfersCAL: {
        route: "/ba-transfers-cal",
        getLink: () => {
            return ROUTES.bankAccountTransfersCAL.route
        } 
    },

    bankAccountTransfersCPADU: {
        route: "/ba-transfers-cpadu",
        getLink: () => {
            return ROUTES.bankAccountTransfersCPADU.route
        } 
    },

    medicalExaminationsCAL: {
        route: "/medical-examinations-cal",
        getLink: () => {
            return ROUTES.medicalExaminationsCAL.route
        } 
    },

    membersTempImport: {
        route: "/members-temp-import",
        getLink: () => {
            return ROUTES.membersTempImport.route
        } 
    },

    dialsClasses: {
        route: "/dials-classes",
        getLink: () => {
            return ROUTES.dialsClasses.route
        } 
    },

    dialsStyles: {
        route: "/dials-styles",
        getLink: () => {
            return ROUTES.dialsStyles.route
        } 
    },
    
    dialsLevels: {
        route: "/dials-levels",
        getLink: () => {
            return ROUTES.dialsLevels.route
        } 
    },

    dialsCompetitionTypes: {
        route: "/dials-competition-types",
        getLink: () => {
            return ROUTES.dialsCompetitionTypes.route
        } 
    },

    dialsCompetitionVariants: {
        route: "/dials-competition-variants",
        getLink: () => {
            return ROUTES.dialsCompetitionVariants.route
        } 
    },    

    dialsAgeCategories: {
        route: "/dials-age-categories",
        getLink: () => {
            return ROUTES.dialsAgeCategories.route
        } 
    },

    dialsCompetitions: {
        route: "/dials-competitions",
        getLink: () => {
            return ROUTES.dialsCompetitions.route
        } 
    },    

    adjudicator: {
        route: "/adjudicator",
        getLink: () => {
            return ROUTES.adjudicator.route
        } 
    },

    master: {
        route: "/master",
        getLink: () => {
            return ROUTES.master.route
        } 
    },

    competition: {
        route: "/competition/:id",
        getLink: (id: string) => {
            return ROUTES.competition.route.replace(":id", id.toString())
        } 
    },

    competitionSchedule: {
        route: "/competitions-list",
        getLink: () => {
            return ROUTES.competitionSchedule.route
        } 
    },

    onlineActions: {
        route: "/online",
        getLink: () => {
            return ROUTES.onlineActions.route
        } 
    },

    onlineDancers: {
        route: "/online-search/:id",
        getLink: (id: string) => {
            return ROUTES.onlineDancers.route.replace(":id", id.toString())
        } 
    },

    onlineData: {
        route: "/online-track/:dancerId/:actionId",
        getLink: (dancerId: string, actionId:string) => {
            return ROUTES.onlineData.route.replace(":dancerId", dancerId.toString()).replace(":actionId", actionId.toString())
        } 
    },

    application: {
        route: "/application/:id/:idCompetitions/:secureCode",
        getLink: (id: string, idCompetitions: string, secureCode: string) => {
            return ROUTES.application.route.replace(":id", id.toString()).replace(":idCompetitions", idCompetitions.toString()).replace(":secureCode", secureCode.toString())
        } 
    },

    applicationMaster: {
        route: "/application963852741/:id/:idCompetitions/:secureCode",
        getLink: (id: string, idCompetitions: string, secureCode: string) => {
            return ROUTES.applicationMaster.route.replace(":id", id.toString()).replace(":idCompetitions", idCompetitions.toString()).replace(":secureCode", secureCode.toString())
        } 
    },

    applicationListByCompetitions: {
        route: "/application-list/:idCompetitions",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByCompetitions.route.replace(":idCompetitions", idCompetitions.toString())
        } 
    },

    applicationListByCompetitionsMaster: {
        route: "/application-list-master/:idCompetitions/:password",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByCompetitionsMaster.route.replace(":idCompetitions", idCompetitions.toString()).replace(":password", password.toString())
        } 
    },

    applicationListByPeople: {
        route: "/application-list-by-app/:idCompetitions/:password",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByPeople.route.replace(":idCompetitions", idCompetitions.toString()).replace(":password", password.toString())
        } 
    },

}

export const RouterSwitch = () => <Switch>
    <Route path="/" exact component={Pages.Home} />
    
    <OrdinaryRoute path={ROUTES.calStartPage.route} component={Pages.CalStartPage} />

    <OrdinaryRoute path={ROUTES.registration.route} component={Pages.AccountsRegistration} />
    <OrdinaryRoute path={ROUTES.registrationCAL.route} component={Pages.AccountsRegistrationCAL} />
    <OrdinaryRoute path={ROUTES.registrationFinishCAL.route} component={Pages.AccountsRegistrationFinishCAL} />

    <OrdinaryRoute path={ROUTES.confirmRegistration.route} component={Pages.AccountsConfirmRegistration} />
    <OrdinaryRoute path={ROUTES.confirmRegistrationCAL.route} component={Pages.AccountsConfirmRegistrationCAL} />

    <OrdinaryRoute path={ROUTES.memberInfoCAL.route} component={Pages.MemberCALInfo} />
    <OrdinaryRoute path={ROUTES.memberInfoCPADU.route} component={Pages.MemberCPADUInfo} />

    <OrdinaryRoute path={ROUTES.memberMedicalExaminationLoadCAL.route} component={Pages.MemberCALMedicalExaminationLoad} />

    <OrdinaryRoute path={ROUTES.onlineActions.route} component={Pages.OnlineActions} />
    <OrdinaryRoute path={ROUTES.onlineDancers.route} component={Pages.OnlineDancers} />
    <OrdinaryRoute path={ROUTES.onlineData.route} component={Pages.OnlineData} />

    <OrdinaryRoute path={ROUTES.competition.route} component={Pages.Competition} />
    <OrdinaryRoute path={ROUTES.competitionSchedule.route} component={Pages.CompetitionSchedule} />
    <OrdinaryRoute path={ROUTES.application.route} component={Pages.Application} />
    <OrdinaryRoute path={ROUTES.applicationMaster.route} component={Pages.ApplicationMaster} />
    <OrdinaryRoute path={ROUTES.applicationListByCompetitions.route} component={Pages.ApplicationListByCompetitions} />
    <OrdinaryRoute path={ROUTES.applicationListByCompetitionsMaster.route} component={Pages.ApplicationListByCompetitionsMaster} />
    <OrdinaryRoute path={ROUTES.applicationListByPeople.route} component={Pages.ApplicationListByPeople} />
    <PublicRoute path={ROUTES.login.route} component={Pages.AccountsLogin} />

    <PrivateRoute path={ROUTES.logout.route} component={Pages.AccountsLogout} />
    <PrivateRoute path={ROUTES.myAccount.route} component={Pages.AccountsMyAccount} />
    <PrivateRoute path={ROUTES.dashboard.route} component={Pages.Dashboard} />
    <PrivateRoute path={ROUTES.members.route} component={Pages.Members} />
    <OrdinaryRoute path={ROUTES.membersTempImport.route} component={Pages.MembersTempImport} />

    <PrivateRoute path={ROUTES.membersCAL.route} component={Pages.MembersCAL} />

    <PrivateRoute path={ROUTES.bankAccountTransfersCAL.route} component={Pages.BankAccountTransfersCAL} />
    <PrivateRoute path={ROUTES.bankAccountTransfersCPADU.route} component={Pages.BankAccountTransfersCPADU} />

    <PrivateRoute path={ROUTES.medicalExaminationsCAL.route} component={Pages.MedicalExaminationsCAL} />

    <PrivateRoute path={ROUTES.dialsClasses.route} component={Pages.DialsClasses} />
    <PrivateRoute path={ROUTES.dialsStyles.route} component={Pages.DialsStyles} />
    <PrivateRoute path={ROUTES.dialsLevels.route} component={Pages.DialsLevels} />
    <PrivateRoute path={ROUTES.dialsCompetitionTypes.route} component={Pages.DialsCompetitionTypes} />
    <PrivateRoute path={ROUTES.dialsCompetitionVariants.route} component={Pages.DialsCompetitionVariants} />
    <PrivateRoute path={ROUTES.dialsAgeCategories.route} component={Pages.DialsAgeCategories} />
    <PrivateRoute path={ROUTES.dialsCompetitions.route} component={Pages.DialsCompetitions} />
    
    <PrivateRoute path={ROUTES.adjudicator.route} component={Pages.Adjudicator} />
    <PrivateRoute path={ROUTES.master.route} component={Pages.Master} />
    

    
    
    <Route>
        <>404</>
    </Route>
</Switch>