//import {URL_MAIN_API} from './UrlApi'
//export const URL_API = URL_MAIN_API

//export const URL_API = sessionStorage.getItem('URL')
export const URL_API = "https://www.prihlaskanasoutez.cz/adminapisut/"
//export const URL_API = window.Appsettings.apiendpoint

export const URL_COMPETITION = URL_API+"competitionsList/readOne.php"
export const URL_COMPETITION_SHEDULE = URL_API+"competitionsList/read.php"
export const URL_APPLICATION_ADD = URL_API+"actions/addApplication.php"
export const URL_APPLICATION_ADD_ITEM = URL_API+"actions/addApplicationItem.php"
export const URL_APPLICATION_DELETE_ITEM = URL_API+"actions/deleteApplicationItem.php"
export const URL_APPLICATION_ADD_MEMBER = URL_API+"actions/addApplicationMember.php"
export const URL_APPLICATION_DELETE_MEMBER = URL_API+"actions/deleteApplicationMember.php"
export const URL_APPLICATION_CONFIRMATION_EMAIL = URL_API+"actions/sendConfirmation.php"
export const URL_APPLICATION_UPDATE_PAYMENT = URL_API+"actions/updateApplicationPayment.php"
export const URL_APPLICATION_READ_ONE = URL_API+"applications/readOne.php"
export const URL_APPLICATION_UPDATE_VARIABLE_NAME = URL_API+"actions/updateVariableName.php"
export const URL_APPLICATION_UPLOAD_MUSIC = URL_API+"actions/uploadMusic.php"
export const URL_APPLICATION_DELETE_MUSIC = URL_API+"actions/deleteMusic.php"

export const URL_ONLINE_ACTIONS = URL_API+"online/readActions.php"
export const URL_ONLINE_DANCERS = URL_API+"online/readDancers.php"
export const URL_ONLINE_DATA = URL_API+"online/readData.php"

export const URL_BANK_ACCOUNT_TRANSFERS_LIST_CAL = URL_API+"bankAccountTransfersCAL/read.php"
export const URL_BANK_ACCOUNT_TRANSFERS_LIST_CPADU = URL_API+"bankAccountTransfersCPADU/read.php"

export const URL_MEDICAL_EXAMINATIONS_LIST_CAL = URL_API+"medicalExaminations/read.php"
export const URL_MEDICAL_EXAMINATIONS_CAL_UPDATE = URL_API+"actions/updateMedicalExaminationTo.php"
export const URL_MEDICAL_EXAMINATIONS_CAL_ACCEPT = URL_API+"actions/acceptMedicalExamination.php"
export const URL_MEDICAL_EXAMINATIONS_CAL_REJECT = URL_API+"actions/rejectMedicalExamination.php"

export const URL_DANCER_LIST = URL_API+"dancers/read.php"
export const URL_DANCER_UPDATE = URL_API+"actions/updateMember.php"
export const URL_DANCER_LEVEL_UPDATE = URL_API+"actions/updateMemberLevel.php"
export const URL_DANCER_DELETE = URL_API+"actions/deleteMember.php"
export const URL_DANCER_ADD = URL_API+"actions/addMember.php"

export const URL_DANCER_CAL_UPDATE = URL_API+"actions/updateMemberCAL.php"

export const URL_DANCER_CAL_LIST = URL_API+"dancersCAL/read.php"

export const URL_MEMBERS_SOLO_DUO = URL_API+"membersSoloDuo/read.php"
export const URL_MEMBERS_SOLO_DUO_DETAIL = URL_API+"membersSoloDuo/readOne.php"
export const URL_MEMBERS_SOLO_DUO_DETAIL_TO_FINISH_REG = URL_API+"membersSoloDuo/readOneToFinishRegistration.php"
export const URL_MEMBERS_SUT_IMPORT = URL_API+"membersSoloDuo/readToImport.php"
export const URL_MEMBERS_SUT_IMPORT_NEW = URL_API+"actions/insertNewMembersSUT.php"

export const URL_MEMBERS_SUT_UPLOAD_MEDICAL = URL_API+"actions/uploadMedicalExamination.php"

export const URL_MEMBERS_PROAM = URL_API+"membersProAm/read.php"
export const URL_MEMBERS_PRO_AM_DETAIL = URL_API+"membersProAm/readOne.php"

export const URL_REGISTRATION_ADD = URL_API+"actions/addRegistration.php"
export const URL_REGISTRATION_CONFIRM = URL_API+"actions/confirmRegistration.php"

export const URL_REGISTRATION_CAL_ADD = URL_API+"actions/addRegistrationCAL.php"
export const URL_REGISTRATION_CAL_CONFIRM = URL_API+"actions/confirmRegistrationCAL.php"
export const URL_REGISTRATION_CAL_FINISH = URL_API+"actions/finishRegistrationCAL.php"

export const URL_CHANGE_STARTNO = URL_API+"actions/changeStartno.php"
export const URL_CHANGE_STARTNO2 = URL_API+"actions/changeStartno2.php"

export const URL_CHANGE_COMPETITION_ITEM_ORDER = URL_API+"actions/changeCompetitionItemOrder.php"

export const URL_DANCER_MEMBERSHIP_UPDATE = URL_API+"actions/updateMemberMembershipTo.php"
export const URL_DANCER_MEDICAL_UPDATE = URL_API+"actions/updateMemberMedicalExaminationTo.php"
export const URL_DANCER_CAL_MEDICAL_UPDATE = URL_API+"actions/updateMemberCALMedicalExaminationTo.php"

export const URL_DANCER_CAL_ADD_PAYMENT = URL_API+"actions/addMemberSoloDuoPayment.php"
export const URL_DANCER_CPADU_ADD_PAYMENT = URL_API+"actions/addMemberCPADUPayment.php"

export const URL_DIALS_CLASSES_LIST = URL_API+"classes/read.php"
export const URL_DIALS_CLASSES_UPDATE = URL_API+"actions/updateClass.php"
export const URL_DIALS_CLASSES_DELETE = URL_API+"actions/deleteClass.php"
export const URL_DIALS_CLASSES_ADD = URL_API+"actions/addClass.php"

export const URL_DIALS_STYLES_LIST = URL_API+"styles/read.php"
export const URL_DIALS_STYLES_UPDATE = URL_API+"actions/updateStyle.php"
export const URL_DIALS_STYLES_DELETE = URL_API+"actions/deleteStyle.php"
export const URL_DIALS_STYLES_ADD = URL_API+"actions/addStyle.php"

export const URL_DIALS_LEVELS_LIST = URL_API+"levels/read.php"
export const URL_DIALS_LEVELS_UPDATE = URL_API+"actions/updateLevel.php"
export const URL_DIALS_LEVELS_DELETE = URL_API+"actions/deleteLevel.php"
export const URL_DIALS_LEVELS_ADD = URL_API+"actions/addLevel.php"

export const URL_DIALS_LEVELS_AGE_CATEGORIES_UPDATE = URL_API+"actions/updateLevelAgeCategories.php"
export const URL_DIALS_LEVELS_AGE_CATEGORIES_DELETE = URL_API+"actions/deleteLevelAgeCategories.php"
export const URL_DIALS_LEVELS_AGE_CATEGORIES_ADD = URL_API+"actions/addLevelAgeCategories.php"

export const URL_DIALS_COMPETITION_TYPES_LIST = URL_API+"competitionTypes/read.php"
export const URL_DIALS_COMPETITION_TYPES_UPDATE = URL_API+"actions/updateCompetitionType.php"
export const URL_DIALS_COMPETITION_TYPES_DELETE = URL_API+"actions/deleteCompetitionType.php"
export const URL_DIALS_COMPETITION_TYPES_ADD = URL_API+"actions/addCompetitionType.php"

export const URL_DIALS_COMPETITION_VARIANTS_LIST = URL_API+"competitionVariants/read.php"
export const URL_DIALS_COMPETITION_VARIANTS_UPDATE = URL_API+"actions/updateCompetitionVariant.php"
export const URL_DIALS_COMPETITION_VARIANTS_DELETE = URL_API+"actions/deleteCompetitionVariant.php"
export const URL_DIALS_COMPETITION_VARIANTS_ADD = URL_API+"actions/addCompetitionVariant.php"

export const URL_DIALS_AGE_CATEGORIES_LIST = URL_API+"ageCategories/read.php"
export const URL_DIALS_AGE_CATEGORIES_UPDATE = URL_API+"actions/updateAgeCategory.php"
export const URL_DIALS_AGE_CATEGORIES_DELETE = URL_API+"actions/deleteAgeCategory.php"
export const URL_DIALS_AGE_CATEGORIES_ADD = URL_API+"actions/addAgeCategory.php"

export const URL_DIALS_COMPETITIONS_LIST = URL_API+"dialsCompetitions/read.php"
export const URL_DIALS_COMPETITIONS_UPDATE = URL_API+"actions/updateDialsCompetition.php"
export const URL_DIALS_COMPETITIONS_DELETE = URL_API+"actions/deleteDialsCompetition.php"
export const URL_DIALS_COMPETITIONS_ADD = URL_API+"actions/addDialsCompetition.php"

export const URL_DIALS_COMPETITIONS_LEVELS_DELETE = URL_API+"actions/deleteDialsCompetitionLevels.php"
export const URL_DIALS_COMPETITIONS_LEVELS_ADD = URL_API+"actions/addDialsCompetitionLevels.php"

export const URL_COMPETITION_APPLICATION_LIST = URL_API+"competitionsList/readApplications.php"
export const URL_COMPETITION_APPLICATION_LIST_BY_PEOPLE = URL_API+"competitionsList/readApplicationsByPeople.php"

export const URL_COMPETITION_ITEMS_WITH_COUNTS_LIST = URL_API+"competitionsList/readCompetitionItemsWithCounts.php"
export const URL_COMPETITION_APP_BY_NAMES_WITH_STARTCOUNTS_LIST = URL_API+"competitionsList/readApplicationsByNamesWithStartCounts.php"

/* ACCOUNTS */
export const URL_ACCOUNT = URL_API+"accounts/"
export const URL_LOGIN = URL_API+"accounts/loginPost.php"
export const URL_REFRESH_TOKEN = URL_API+"accounts/refresh-token"
export const URL_LOGOUT = URL_API+"accounts/logout"
export const URL_FORGOTTEN_PASSWORD = URL_API+"accounts/forgotten-password"
export const URL_RESET_PASSWORD = URL_API+"accounts/reset-password"
export const URL_REGISTRATION = URL_API+"accounts/registration"
export const URL_CONFIRM_REGISTRATION = URL_API+"accounts/confirm-registration"
export const URL_CHANGE_PASSWORD = URL_API+"accounts/change-password"
export const URL_RESEND_CONFIRMATION_EMAIL = URL_API+"accounts/resend-confirmation-email"

/* INVITES */
export const URL_INVITES = URL_API+"invites"
export const URL_INVITES_EMAIL_FROM_TOKEN = URL_API+"accounts/email-from-invite/"

/* CUSTOMERS */
export const URL_CUSTOMER_LIST  = URL_API+"customers"
export const URL_CUSTOMER_OPEN  = URL_API+"customers/open/"
export const URL_CUSTOMER_CLOSE = URL_API+"customers/close/"
export const URL_CUSTOMER_CATEGORIES = URL_API+"customers/"
export const URL_CUSTOMER_USERS = URL_API+"customers/"
export const URL_CUSTOMER_OFFER_COUNT = URL_API+"customers/"
export const URL_CUSTOMER_CHANGE_STATE = URL_API+"customers/"
export const URL_CUSTOMER_LICENSES = URL_API+"customers/"
export const URL_CUSTOMER_SERVICES = URL_API+"customers/"
export const URL_CUSTOMER_GDPR = URL_API+"customers/"
export const URL_CUSTOMER_UPGRADE_OFFERS = URL_API+"customers/"
export const URL_CUSTOMER_EMAILS_SENT = URL_API+"admin/emails/customers/"

/* OVERVIEWS */
export const URL_OVERVIEWS_CUSTOMERS = URL_API+"overviews/customers"
export const URL_OVERVIEWS_OFFERS = URL_API+"overviews/offers"

/* DATAFILTERS */
export const URL_DATAFILTERS_CUSTOMERS = URL_API+"datafilters/customers/"
export const URL_DATAFILTERS_OFFERS = URL_API+"datafilters/offers/"

/* TEMPLATES */
export const URL_TEMPLATES = URL_API+"templates"

/* BULK OPERATIONS */
export const URL_BULK_INVITES = URL_API+"bulkoperations/invites"
export const URL_BULK_REMINDERS = URL_API+"bulkoperations/reminders"
export const URL_BULK_EMAILS  = URL_API+"bulkoperations/emails"
export const URL_BULK_OFFERS  = URL_API+"bulkoperations/offers"

/* CUSTOMER LICENSES */
export const URL_LICENSES_STATE = URL_API+"licenses/"
export const URL_TRANSFER_CUSTOMER_LICENSES = URL_API+"licenses/customer/"
export const URL_TRANSFER_LICENSE = URL_API+"licenses/transfer"

/* CUSTOMER SERVICES */
export const URL_SERVICES = URL_API+"services/customer/"

/* OFFERS */
export const URL_OFFERS = URL_API+"offers/"
export const URL_OFFERS_SCENARIOS = URL_API+"offers/scenarios"
export const URL_OFFERS_CALCULATE = URL_API+"offers/calculate"

/* DIALS */
export const URL_DIALS_CATEGORIES  = URL_API+"dials/categories"
export const URL_DIALS_PROGRAMS  = URL_API+"dials/programs"
export const URL_DIALS_PROGRAM_VERSIONS  = URL_API+"dials/program-versions?programId="
export const URL_DIALS_LICENSE_TYPES  = URL_API+"dials/license-types?programId="
export const URL_DIALS_SERVICES  = URL_API+"dials/services"
export const URL_DIALS_MODULES  = URL_API+"dials/modules?programId="
export const URL_DIALS_PRICE_LISTS_MODULES  = URL_API+"dials/price-lists/modules?programId="
export const URL_DIALS_PRICE_LISTS_SERVICES  = URL_API+"dials/price-lists/services"

/* DOCUMENTS */
export const URL_DOCUMENTS  = URL_API+"customers/"

/* ADMIN */
export const URL_ADMIN_EMAILS_SENT = URL_API+"admin/emails/email"
export const URL_CHANGES = URL_API+"changes/"


/* CALCULATORS */


export const JEJDA = "Nastala chyba!"
export const LOGIN_JEJDA = "Nastala chyba!"

export const CLIENT_URL = "https://ts-sway.cz/files/apiUsers.php"
export const CLIENT_UPDATE_URL = "https://ts-sway.cz/files/apiUsersUpdate.php"
export const ORDER_URL = "https://ts-sway.cz/files/apiOrders.php"
export const ORDER_UPDATE_URL = "https://ts-sway.cz/files/apiOrdersUpdate.php"

