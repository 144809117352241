import { types } from 'mobx-state-tree'

const ApplicationItems = types.model('ApplicationItems',{
  idCompetitionItems: types.optional(types.string, ''),
  idCompetitions: types.optional(types.string, ''),
  idGroup: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  nameEN: types.maybeNull(types.string),
  name2: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  style: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  allowVariableName: types.maybeNull(types.string),
  hdid: types.maybeNull(types.string),
  
})


const Applications = types.model('Applications',{
    idApplications: types.optional(types.string, ''),
    idCompetitions: types.optional(types.string, ''),
    firstName1: types.maybeNull(types.string),
    surName1: types.maybeNull(types.string),
    cpadu: types.maybeNull(types.string),
    registeredSUT1: types.maybeNull(types.number),
    paymentsSUT1: types.optional(types.number,0),
    firstName2: types.maybeNull(types.string),
    surName2: types.maybeNull(types.string),
    cpadu2: types.maybeNull(types.string),
    registeredSUT2: types.maybeNull(types.number),
    paymentsSUT2: types.optional(types.number,0),
    firstName3: types.maybeNull(types.string),
    surName3: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    date1: types.maybeNull(types.string),
    date2: types.maybeNull(types.string),
    date3: types.maybeNull(types.string),
    idMembers1: types.maybeNull(types.string),
    idMembers2: types.maybeNull(types.string),
    idMembers3: types.maybeNull(types.string),
    lastPaidYear1: types.optional(types.number,0),
    lastPaidYear2: types.optional(types.number,0),
    lastPaidYear3: types.optional(types.number,0),
    medicalExaminationTo1: types.maybeNull(types.string),
    medicalExaminationTo2: types.maybeNull(types.string),
    medicalExaminationTo3: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    secureCode: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    paid: types.maybeNull(types.string),
    startno: types.optional(types.number, 0),
    startno2: types.optional(types.string, ''),
    price: types.optional(types.number, 0),
    firstMinutePrice: types.optional(types.number, 0),
    price2: types.optional(types.number, 0),
    firstMinutePrice2: types.optional(types.number, 0),
    dancesCount: types.optional(types.number, 0),
    promoCode: types.optional(types.string, ''),
    applicationItems: types.array(ApplicationItems),
})
.views(self => ({
  get names() {
    let names:string = ''
    
    let name1txt:string = self.firstName1 + ' ' + self.surName1
    let name2txt:string = self.firstName2 + ' ' + self.surName2
    let name3txt:string = self.firstName3 + ' ' + self.surName3

    let name1trim:string = name1txt.trim()
    let name2trim:string = name2txt.trim()
    let name3trim:string = name3txt.trim()

    names = name1trim

    if (names !== '') {
      if (name2trim !== '') {
        names = names + ' & ' + name2trim
      }
      
    }
    else {
      names = name2trim
    }


    if (names !== '') {
      if (name3trim !== '') {
        names = names + ' & ' + name3trim
      }
      
    }
    else {
      names = name3trim
    }

    return names
  },
  get medicalExaminationState1() {
    if (self.medicalExaminationTo1 === null || self.medicalExaminationTo1 === '') return false
    else {
      return new Date(self.medicalExaminationTo1!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? true:false
    }

  },

  get medicalExaminationState2() {
    if (self.medicalExaminationTo2 === null || self.medicalExaminationTo2 === '') return false
    else {
      return new Date(self.medicalExaminationTo2!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? true:false
    }

  },

  get medicalExaminationState3() {
    if (self.medicalExaminationTo3 === null || self.medicalExaminationTo3 === '') return false
    else {
      return new Date(self.medicalExaminationTo3!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? true:false
    }

  },
}))
.actions(self => ({
    setPaid(value:string) {
      self.paid = value
    }

}))

export const CompetitionAppListByPeople = types
  .model({
    idCompetitions: types.optional(types.string, ''),
    date: types.maybeNull(types.string),
    dateTo: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
    sut: types.maybeNull(types.number),
    applicationsDate: types.maybeNull(types.string),
    firstMinutePriceTo: types.maybeNull(types.string),
    allowSolos: types.maybeNull(types.string),
    allowDuets: types.maybeNull(types.string),
    allowTeams: types.maybeNull(types.string),
    allowFormations: types.maybeNull(types.string),
    allowProams: types.maybeNull(types.string),
    allowCouples: types.maybeNull(types.string),
    allowPara: types.maybeNull(types.string),  
    labelSolo: types.optional(types.string,''),
    labelSoloEn: types.optional(types.string,''),
    labelDuo: types.optional(types.string,''),
    labelDuoEn: types.optional(types.string,''),
    labelCouple: types.optional(types.string,''),
    labelCoupleEn: types.optional(types.string,''),
    labelTeam: types.optional(types.string,''),
    labelTeamEn: types.optional(types.string,''),
    showAppDetailCompetitions: types.optional(types.string,'0'),
    allowPromoCode: types.optional(types.boolean,false),
    applications: types.array(Applications)

  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
