import React, { useState } from 'react'
import axios from 'axios'

import { useParams } from 'react-router'
//import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'
import {Container,  Form, Col, Button, Alert, Image, Row, Card, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'

import { useStore } from '../../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Recaptcha from 'react-recaptcha'

import Loader from 'react-loader'
import moment from 'moment'

import { observer } from 'mobx-react'

function MemberInfo(props:any) {

  const [loaded, setLoaded] = useState(true);
  
  const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  
  // Získání tokenu z URL adresy
  

  // Kontrola zda se načetla URL BE
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }

  const rootStore = useStore()

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [loadedId, setLoadedId] = useState('')
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [emailFromToken, setEmailFromToken] = useState('')

    const [selectedMember, setSelectedMember] = useState('')

    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)
      setShowForm('')
      // Dotažení e-mailu z tokenu
      //await rootStore.fetchCountries()
      await rootStore.fetchMembersSoloDuo()
      
      setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })



  // Deklarace a nastavení stavů pro seznamy ala google
  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const membersAllF = rootStore.membersSoloDuo

  const [members, setMembers] = useState(membersAllF.filter(cl => cl.firstName?.includes('') || cl.surName?.includes('')))  

  // Filtrování zákazníků v seznamu
  const filterMembers = (value:string) => {
    setMembers(rootStore.getMembersAllByFilter(value))
  }

  const loadedMember = rootStore.membersSoloDuo.find(m => m.id === loadedId)

  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 



// O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownMember =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (members[activeItem]?.id !== undefined) {
        setFieldValue('search1',members[activeItem].id)
        setSelectedMember(members[activeItem].id)
        
        //handleOld(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('search1','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((members && activeItem === members.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

};


  // Po odeslání registračního formuláře
  const handleSearch = (values:any) => {
    setError(null)
    setLoading(true)

    // Zavolání API
    //axios.post(Constants.URL_REGISTRATION_ADD, { surName: values.surName, firstName: firstNameData, birthdate: birthdateData, personalIdentificationNumber: personalIdentificationNumberData, club: clubData, country: countryData, email: emailData, phone: phoneData}
    axios.get(Constants.URL_MEMBERS_SOLO_DUO_DETAIL,{params: {
          id: values.search1
    }}
    ).then(response => {
      rootStore.membersSoloDuo.find(m => m.id === values.search1)?.updateMemberFromData(response.data)
      setLoadedId(values.search1)
      setLoading(false)
      
      setShowInfo(true)
      setShowForm('nodisplay')
    }).catch(error => {
      setLoading(false);
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    search1: Yup.string()
      .required('ID člena musí být vyplněno!'),

    recaptcha: Yup.string().required('Ověření je vyžadováno!'), 


  })  

  return (
    <div className="App">
      <main>
      <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    
                    <div>
                      
                    </div>
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="images/cal-small.png" width={130} className="login-logo" /> Informační systém</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Vyhledání informací o členovi</h3>
                    
                    {showInfo && <>
                      <Card>
                        <Card.Header><h5>Detail člena</h5></Card.Header>
                        <Card.Body>
                          <h5>Základní údaje</h5>
                          <Row>
                            <Col md={4}>ID:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.id}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>Příjmení:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.surName?.toLocaleUpperCase()}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>Jméno:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.firstName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>Klub:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.club}&nbsp;({loadedMember?.country})</Col>
                          </Row>
                          <br />
                          <h5>Soutěžní údaje</h5>
                          <Row>
                            <Col md={4}>Třída sólo STT:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.classSoloSTTName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>Třída sólo LAT:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.classSoloLATName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>Třída sólo CAR:</Col>
                            <Col style={{fontWeight: 'bold'}}>{loadedMember?.classSoloCARName}</Col>
                          </Row>
                          <br />
                          <h5>Zdravotní způsobilost</h5>
                          <Row>
                            <Col md={4}>Potvrzení do:</Col>
                            <Col style={{fontWeight: 'bold'}}>
                              {(loadedMember?.medicalExaminationState! === 0) && <> 
                                Nezasláno&nbsp;
                                <Badge variant="danger">Neplatné</Badge>
                                </>}
                              {(loadedMember?.medicalExaminationState! > 0) && <>
                                {moment(loadedMember?.medicalExaminationTo).format("DD.MM.YYYY")}&nbsp;
                                <Badge variant={loadedMember?.medicalExaminationState! === 1 ?  "success":"danger"}>{loadedMember?.medicalExaminationState! === 1 ?  "Platné":"Neplatné"}</Badge>
                              </>}
                              
                            </Col>
                          </Row>
                          {loadedMember?.medicalExaminationState! !== 1 &&
                          <>
                            
                            <Alert variant="warning" style={{marginTop: '20px'}}><strong>Upozornění:</strong>&nbsp;Tanečník nemá platnou zdravotní způsobilost!<hr /><span style={{fontStyle: 'italic'}}>Potvrzení o zdravotní způsobilosti je možné do systému zasílat přes <a href='https://easyapp.prihlaskanasoutez.cz/#/member-medical-cal' target='_blank'>tento formulář</a>.</span></Alert>

                          </>}

                          <br />
                          <h5>Členské příspěvky</h5>
                          <Row>
                            <Col md={4}>Uhrazeno do:</Col>
                            <Col style={{fontWeight: 'bold'}}>
                              {(loadedMember?.lastPaidYear === 0) && <> 
                                Neuhrazeno&nbsp;
                                <Badge variant="danger">Neplatné</Badge>
                                </>}
                              {(loadedMember?.lastPaidYear !== 0 && loadedMember?.lastPaidYear! < new Date().getFullYear()) && <> 
                                31.12.{loadedMember?.lastPaidYear}&nbsp;
                                <Badge variant="danger">Neplatné</Badge>
                                </>}
                              {(loadedMember?.lastPaidYear !== 0 && loadedMember?.lastPaidYear! >= new Date().getFullYear()) && <> 
                                31.12.{loadedMember?.lastPaidYear}&nbsp;
                                <Badge variant="success">Platné</Badge>
                                </>}
                            </Col>
                          </Row>
                          {loadedMember?.lastPaidYear! < new Date().getFullYear() &&
                          <Alert variant="danger" style={{marginTop: '20px'}}>
                            <strong>Upozornění:</strong><br />Tanečník nemá zaplacené členské příspěvky
                            <hr />
                            <div style={{float: 'left'}}>
                            <strong>Platební informace:</strong><br />
                            Číslo účtu: 2001919886/2010<br />
                            Variabilní symbol: {loadedMember?.id}<br />
                            Specifický symbol: {new Date().getFullYear()}<br />
                            Částka: 300 Kč
                            </div>
                            <div style={{float: 'right'}}>
                            <img width={150} style={{paddingTop: '10px'}} alt="QR platba" src={"https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=2001919886&bankCode=2010&amount=300&currency=CZK&vs="+loadedMember?.id+"&message=Clenske-prispevky&size=150"}/>
                            </div>
                            <div style={{clear: 'both'}}></div>
                          
                          </Alert>}

                        </Card.Body>
                      </Card>
                      <br />
                      <Button variant='secondary' onClick={() => {setShowInfo(false); setLoadedId(''); setShowForm('');}}>Zpět na vyhledávání</Button>
                    
                    </>}

                    <div className={showForm} >
                      <Formik
                        validationSchema={schema}
                        initialValues={{ 
                          search1: '',
                          recaptcha: '',
                        }}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleSearch(values)              
                            setSubmitting(false);
                          }, 10);
                        }}
                      >
                        {({ 
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          validateField,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                            <FormGroup controlId="search1">
                                <Form.Row>
                                  <FormLabel column lg={3}>Vyhledávání</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="text"
                                      name="search1"
                                      style={{backgroundColor: 'mintcream'}}
                                      value={values.search1}
                                      autoComplete="off" 
                                      autoFocus={true}
                                      onChange={(e) => {handleChange(e);  state.displayItems = true; filterMembers(e.target.value); }}
                                      onKeyDown={(e:any) => handleKeyDownMember(e, setFieldValue, 1)}
                                      onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },500 ) }}
                                      
                                      isValid={touched.search1 && !errors.search1} 
                                      isInvalid={!!errors.search1}
                                    />
                                    {values.search1! !== '' && state.displayItems ?
                                      <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                          <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '430px', cursor: 'pointer'}}>
                                          {
                                              members.map((dp,index) => {
                                                  return (
                                                      <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                          //onClick={() => console.log('A')}
                                                          onClick={async (e:any) => { await setFieldValue('search1',dp.id);  setSelectedMember(dp.id); setState({activeItem: state.activeItem, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue }); validateField('search1');   }}
                                                          key = {dp.id}
                                                          >
                                                          {dp.surName} {dp.firstName} ({dp.club}, ID: {dp.id})
                                                      </li>
                                                  )
                                              }).slice(0, 50)
                                          }
                                          </ul>
                                      </div>
                                      : null
                                  }
                                    <Form.Control.Feedback type="invalid">
                                      {errors.search1}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>           

                            <FormGroup controlId="recaptcha">
                              <Form.Row>
                                <FormLabel column lg={3}>&nbsp;</FormLabel>
                                <Col>
                                <Recaptcha
                                  sitekey="6LeZxTMcAAAAAIlveWP5kv9e7fTtC5dTyTYATvWi"
                                  render="explicit"
                                  hl="cs"
                                  verifyCallback={async (response) => { await setFieldValue("recaptcha", response); validateField('recaptcha'); validateField('search1'); }}
                                  onloadCallback={() => { console.log("done loading!"); }}
                                />
                                {errors.recaptcha 
                                  && touched.recaptcha && (
                                  <p className="invalid-feedback" style={{display: "block"}}>{errors.recaptcha}</p>
                                )}
                                  
                                  <Form.Control.Feedback type="invalid">
                                    {errors.recaptcha}
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Row>  
                            </FormGroup>  
                            {error}
                            <br />
                            <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Získávám informace...' : 'Zobrazit detail člena'}</Button>
                            
                          </Form>
                        )}
                      </Formik>  
                    </div>    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
        </Loader>
      </main>
    </div>
  )
}

export default withRouter(observer(MemberInfo))