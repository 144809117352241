import { stat } from 'fs'
import { types } from 'mobx-state-tree'
import { string } from 'yup'

const Levels = types.model('Levels',{
    idMemberLevels: types.optional(types.string, ''),
    idMembers: types.optional(types.string, ''),
    idLevels: types.maybeNull(types.string),
    points: types.maybeNull(types.string),
    finals: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
    styleId: types.maybeNull(types.string),
    styleName: types.maybeNull(types.string),
    styleShortcut: types.maybeNull(types.string),
    classShortcut: types.maybeNull(types.string)
})


const Payments = types.model('Payments',{
  id: types.optional(types.string, ''),
  idMembers: types.optional(types.string, ''),
  idTransaction: types.maybeNull(types.string),
  createDate: types.optional(types.string,''),
  variableSymbol: types.maybeNull(types.string),
  year: types.maybeNull(types.number),
  price: types.maybeNull(types.number),
  note: types.maybeNull(types.string)
})

const MedicalExaminations = types.model('MedicalExaminations',{
  idMedicalExaminationUploads: types.optional(types.string, ''),
  idMembers: types.optional(types.string, ''),
  medicalExaminationTo: types.maybeNull(types.string),
  uploaded: types.optional(types.string,''),
  accepted: types.optional(types.boolean,false),
  rejected: types.optional(types.boolean,false),
  fileId: types.maybeNull(types.string),
})
.views(self => ({
  get state() {
    let state = 0

    if (self.accepted) state = 1
    if (self.rejected) state = 2

    return state
  },
  get stateText() {
    let state = 'Čeká na schválení'

    if (self.accepted) state = 'Schváleno'
    if (self.rejected) state = 'Zamítnuto'

    return state
  },

}))

export const DancerListCAL = types
  .model({
    id: types.optional(types.string, ''),
    surName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    birthdate: types.maybeNull(types.string),
    personalIdentificationNumber: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),

    street: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    identificationNumber: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    yesOne: types.optional(types.boolean, false),
    yesTwo: types.optional(types.boolean, false),
    yesThree: types.optional(types.boolean, false),
    yesFour: types.optional(types.boolean, false),
    adultPerson: types.maybeNull(types.string),



    //membershipTo: types.maybeNull(types.string),
    
    medicalExaminationTo: types.maybeNull(types.string),
    registered: types.maybeNull(types.string),
    active: types.maybeNull(types.string),  
    //levels: types.array(Levels)
    classSoloSTT: types.optional(types.string, ''),
    classSoloLAT: types.optional(types.string, ''),
    classSoloCAR: types.optional(types.string, ''),
    soloDuo: types.optional(types.boolean, false),
    couples: types.optional(types.boolean, false),
    lastPaidYear: types.optional(types.number, 0),
    payments: types.array(Payments),
    medicalExaminations: types.array(MedicalExaminations)
  })
  .views(self => ({
    get memberAfterMedicalExamination() {
      
      return new Date(self.medicalExaminationTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
    /*
    get memberAfterMembership() {
      
      return new Date(self.membershipTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
    */
    get searchString() {
      //eslint-disable-next-line
      let searchString:string = ''

      let fullName1:string = ''
      if (self.firstName === null || self.firstName === '') fullName1 = self.surName! 
      else fullName1 = (self.surName! === null ? '' : self.surName + ' ' ) + self.firstName

      let club:string = ''
      if (self.club !== null) club = self.club

      let country:string = ''
      if (self.country !== null) country = self.country

      return searchString = fullName1+' '+club+' '+country
    },
    get searchStringId() {
      //eslint-disable-next-line
      let searchString:string = ''

      let search1:string = ''
      if (self.personalIdentificationNumber === null || self.personalIdentificationNumber === '') search1 = self.id! 
      else search1 = self.id + ' ' + self.personalIdentificationNumber


      return searchString = search1
    },
    get statusNumber() {
      let statusNumber:string = ''
      
      if (self.soloDuo && self.couples) statusNumber = '3'
      if (self.soloDuo && !self.couples) statusNumber = '1'
      if (!self.soloDuo && self.couples) statusNumber = '2'
      
      return statusNumber
    },
    get classSoloSTTName() {
      let className:string = ''

      if (self.classSoloSTT === 'H') className = 'Hobby D'
      if (self.classSoloSTT === 'S') className = 'Sport C'

      return className
    },
    get classSoloLATName() {
      let className:string = ''

      if (self.classSoloLAT === 'H') className = 'Hobby D'
      if (self.classSoloLAT === 'S') className = 'Sport C'

      return className
    },
    get classSoloCARName() {
      let className:string = ''

      if (self.classSoloCAR === 'H') className = 'Hobby D'
      if (self.classSoloCAR === 'S') className = 'Sport C'

      return className
    },
  }))
  .actions(self => ({
    

  }))
