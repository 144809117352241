import React, { useState, useRef } from 'react'
import axios from 'axios'

import { useParams } from 'react-router'
//import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'
import {Container,  Form, Col, Button, Alert, Image, Row, Card, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'

import { useStore } from '../../../store'

import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import Recaptcha from 'react-recaptcha'

import Loader from 'react-loader'
import moment from 'moment'

import { observer } from 'mobx-react'

function MemberCALMedicalExaminationLoad(props:any) {

  const [loaded, setLoaded] = useState(true);
  
  const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  
  // Získání tokenu z URL adresy
  

  // Kontrola zda se načetla URL BE
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }

  const rootStore = useStore()

  const formikRef = useRef<FormikProps<any>>(null);

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [loadedId, setLoadedId] = useState('')
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [emailFromToken, setEmailFromToken] = useState('')

    const [selectedMember, setSelectedMember] = useState('')

    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)
      setShowForm('')
      // Dotažení e-mailu z tokenu
      //await rootStore.fetchCountries()
      await rootStore.fetchMembersSoloDuo()
      
      setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })



  // Deklarace a nastavení stavů pro seznamy ala google
  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const membersAllF = rootStore.membersSoloDuo

  const [members, setMembers] = useState(membersAllF.filter(cl => cl.firstName?.includes('') || cl.surName?.includes('')))  

  // Filtrování zákazníků v seznamu
  const filterMembers = (value:string) => {
    setMembers(rootStore.getMembersAllByFilter(value))
  }

  const loadedMember = rootStore.membersSoloDuo.find(m => m.id === loadedId)

  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 



// O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownMember =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (members[activeItem]?.id !== undefined) {
        setFieldValue('search1',members[activeItem].id)
        setSelectedMember(members[activeItem].id)
        
        //handleOld(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('search1','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((members && activeItem === members.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

};


const handleDownloadDocument = async (fileId:string, fileNameToSave:string) => {
  const fileUrl = 'https://easyapp.prihlaskanasoutez.cz/files/medicalExaminations/'+fileId; // URL souboru ke stažení
  const fileName = fileNameToSave; // Nový název souboru

  try {
    const response = await fetch(fileUrl, { credentials: 'same-origin' });
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();

    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName; // Nastavení požadovaného názvu souboru
    a.type = 'application/octet-stream';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Download error:', error);
  }
  /*
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName; // Nastavení požadovaného názvu souboru
  a.type = 'application/octet-stream';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  */
}

const handleUpload = (values:any) => {
  setError(null);
  //setLoading(true);
  
  // Zpracování požadavku
  (async () => {

    const data = new FormData() 
    
    data.set('idMembers', values.search1)
    data.set('birthdate', values.birthdate)
    data.set('medicalExaminationTo', values.medicalExaminationTo)
    data.set('email', values.email)

    if (newFile !== null) data.append('file', newFile!)

    // Zavolání API
    await axios.post(Constants.URL_MEMBERS_SUT_UPLOAD_MEDICAL, data
      ).then(response => {
          setLoading(false);
          setShowInfo(true)
          setShowForm('nodisplay')
      }).catch(error => {
          setLoading(false);
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
      });
  })()
}




 

    const [newFile, setNewFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState(0);
  
    const setFile = async (evt:any) => {
      await setNewFile(evt.target.files[0]);
      await setFileName(evt.target.files[0].name);
      await setFileSize(evt.target.files[0].size);
      
      await console.log(fileName);
      //await console.log(fileSize);
      //await console.log(fileType);
    }
  
    const SUPPORTED_FORMATS = [".pdf", ".PDF"];
    const FILE_SIZE = 5 * 1024 * 1024;

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    search1: Yup.string()
      .required('ID člena musí být vyplněno!'),

    birthdate: Yup.date().required("Datum narození musí být vyplněno!"),

    recaptcha: Yup.string().required('Ověření je vyžadováno!'), 
    medicalExaminationTo: Yup.date()
    .required("Datum platnosti prohlídky musí být vyplněno!")
    .min(moment().format('YYYY-MM-DD'), 'Datum prohlídky musí být větší než dnešní datum!'),

    email: Yup.string()
    .required("E-mail musí být vyplněn!")
    .email("E-mail musí být ve správném formátu!"),

    file: Yup.mixed() 
    .required("Soubor musí být vybrán!")
    .test('fileType', "Nepovolená přípona souboru", value => SUPPORTED_FORMATS.includes(fileName.substr(fileName.length - 4, 4).toLowerCase()) )
    .test('fileSize', "Překročena maximální velikost souboru (5 MB)", value => fileSize <= FILE_SIZE) 
    /*.test('fileType', "Nepovolená přípona souboru", value => {
      if (!value) return false;  // pokud není žádný soubor
      //const fileName = value.name;  // získání názvu souboru
      const extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();  // získání přípony souboru
      return SUPPORTED_FORMATS.includes(`.${extension}`);
    })*/


  })  

  return (
    <div className="App">
      <main>
      <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    
                    <div>
                      
                    </div>
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="images/cal-small.png" width={130} className="login-logo" /> Informační systém</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Nahrávání zdravotních způsobilostí</h3>
                    
                    <Alert variant="success" show={showInfo}>
                      <strong>Dokument s potvrzením zdravotní způsobilosti byl úspěšně nahrán!​</strong>
                      <hr />
                      <Button variant='secondary' onClick={async () => {await setNewFile(null); await setFileName(''); await setFileSize(0); await formikRef.current?.resetForm();  setShowInfo(false);  setShowForm('');}}>Nahrát další dokument</Button>
                    </Alert>  

                    <div className={showForm} >
                      <Formik
                        innerRef={formikRef}
                        validationSchema={schema}
                        initialValues={{ 
                          search1: '',
                          recaptcha: '',
                          birthdate: '',
                          file: '',
                          medicalExaminationTo: '',
                          email: ''
                        }}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleUpload(values)              
                            setSubmitting(false);
                          }, 10);
                        }}
                      >
                        {({ 
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                          validateField,
                          validateForm,
                          resetForm,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                            <FormGroup controlId="search1">
                                <Form.Row>
                                  <FormLabel column lg={4}>Vyhledávání soutěžícího</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="text"
                                      name="search1"
                                      style={{backgroundColor: 'mintcream'}}
                                      value={values.search1}
                                      autoComplete="off" 
                                      autoFocus={true}
                                      onChange={(e) => {handleChange(e);  state.displayItems = true; filterMembers(e.target.value); }}
                                      onKeyDown={(e:any) => handleKeyDownMember(e, setFieldValue, 1)}
                                      onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },500 ) }}
                                      
                                      isValid={touched.search1 && !errors.search1} 
                                      isInvalid={!!errors.search1}
                                    />
                                    {values.search1! !== '' && state.displayItems ?
                                      <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                          <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '430px', cursor: 'pointer'}}>
                                          {
                                              members.map((dp,index) => {
                                                  return (
                                                      <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                          //onClick={() => console.log('A')}
                                                          onClick={async (e:any) => { await setFieldValue('search1',dp.id);  setSelectedMember(dp.id); setState({activeItem: state.activeItem, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue }); validateField('search1');   }}
                                                          key = {dp.id}
                                                          >
                                                          {dp.surName} {dp.firstName} ({dp.club}, ID: {dp.id})
                                                      </li>
                                                  )
                                              }).slice(0, 50)
                                          }
                                          </ul>
                                      </div>
                                      : null
                                  }
                                    <Form.Control.Feedback type="invalid">
                                      {errors.search1}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>  
                              

                              <FormGroup controlId="birthdate">
                                <Form.Row>
                                  <FormLabel column lg={4}>Datum narození</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="date"
                                      name="birthdate"
                                      value={values.birthdate!}
                                      onChange={async (e) => { await handleChange(e); await validateField('birthdate'); }}
                                      isValid={touched.birthdate && !errors.birthdate} 
                                      isInvalid={!!errors.birthdate}
                                    />
                                    
                                    <Form.Control.Feedback type="invalid">
                                      {errors.birthdate}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>

                              <FormGroup controlId="email">
                                <Form.Row>
                                  <FormLabel column lg={4}>Kontaktní e-mail</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="text"
                                      name="email"
                                      value={values.email!}
                                      onChange={async (e) => { await handleChange(e); await validateField('email'); }}
                                      isValid={touched.email && !errors.email} 
                                      isInvalid={!!errors.email}
                                    />
                                    
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>




                              <FormGroup controlId="file">
                                <Form.Row>
                                  <FormLabel column lg={4} style={{fontWeight: 'bold'}}>Soubor PDF s potvrzením</FormLabel>
                                  <Col>
                                  <label style={{border: 'none',display: 'inline-block', cursor: 'pointer', marginRight: '12px'}}>
                                    <input
                                      type="file"
                                      id="file" 
                                      name="file"
                                      className="form-control"
                                      value={values.file!} 
                                      onChange={async (e) => {await handleChange(e); await setFile(e);  await validateField('file') }}
                                      style={{display: 'none'}}
                                    />
                                        <span className='btn btn-primary btn-sm'>Výběr souboru...</span> 
                                    </label>
                                    {fileName}


                                    
                                      <div className="invalid-feedback" style={{display: 'block', fontSize: '12px'}}>{errors.file}</div>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.file}
                                      </Form.Control.Feedback>
                                  </Col>
                                  
                                </Form.Row>  
                              </FormGroup>

                              <FormGroup controlId="medicalExaminationTo">
                                <Form.Row>
                                  <FormLabel column lg={4} style={{fontWeight: 'bold'}}>Platnost prohlídky do</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="date"
                                      name="medicalExaminationTo"
                                      value={values.medicalExaminationTo!}
                                      onChange={async (e) => { await handleChange(e); await validateField('medicalExaminationTo'); }}
                                      isValid={touched.medicalExaminationTo && !errors.medicalExaminationTo} 
                                      isInvalid={!!errors.medicalExaminationTo}
                                    />
                                    
                                    <Form.Control.Feedback type="invalid">
                                      {errors.medicalExaminationTo}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>



                            <FormGroup controlId="recaptcha">
                              <Form.Row>
                                <FormLabel column lg={4}>&nbsp;</FormLabel>
                                <Col>
                                <Recaptcha
                                  sitekey="6LeZxTMcAAAAAIlveWP5kv9e7fTtC5dTyTYATvWi"
                                  render="explicit"
                                  hl="cs"
                                  verifyCallback={async (response) => { await setFieldValue("recaptcha", response); validateField('recaptcha'); validateField('search1'); }}
                                  onloadCallback={() => { console.log("done loading!"); }}
                                />
                                {errors.recaptcha 
                                  && touched.recaptcha && (
                                  <p className="invalid-feedback" style={{display: "block"}}>{errors.recaptcha}</p>
                                )}
                                  
                                  <Form.Control.Feedback type="invalid">
                                    {errors.recaptcha}
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Row>  
                            </FormGroup>  
                            {error}
                            <br />
                            <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Nahrávám dokument...' : 'Nahrát dokument'}</Button>
                            
                            <Alert variant="secondary" style={{marginTop: '20px'}}>
                              <strong><i className="fas fa-info-circle"></i></strong>&nbsp;<i>Pokud nemůžete své jméno najít v databázi a registraci jste prováděli před 1.1.2025, je možné, že došlo k chybě při převodu dat z původní databáze. Vyplňte prosím novou registraci člena do CAL kliknutím na následující odkaz - <a href='https://easyapp.prihlaskanasoutez.cz/#/registration-cal' >Registrace nového člena</a>. Děkujeme za pochopení a omlouváme se za komplikace!</i><br />
                            </Alert>
                          </Form>
                        )}
                      </Formik>  
                    </div>    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
        </Loader>
      </main>
    </div>
  )
}

export default withRouter(observer(MemberCALMedicalExaminationLoad))