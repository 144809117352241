import React, {useState}  from 'react'
import Loader from "react-loader"
import { ROUTES } from '../../Routes'
import { NavLink } from 'react-router-dom'

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Container, CardDeck, Alert, Row, Col, Table, Badge, Form, ButtonGroup, Button } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore, useUpgradeStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent } from '../../Utils/Common'

import moment from 'moment'
import NumberFormat from 'react-number-format'
import MedicalExaminationsUpdate from './components/MedicalExaminations.Update.part'

import Pagination from 'react-js-pagination'

import { confirm } from "../../Utils/Confirmation";

import * as Constants from '../../Constants/Constants'
import axios from 'axios';


function MedicalExaminationsCAL(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  // Nastavení práv zda jsme Ježci
  
  const [filterName, setFilterName] = useState(!(localStorage.getItem('examinationsCALFilterName') === undefined || localStorage.getItem('examinationsCALFilterName') === null)  ? localStorage.getItem('examinationsCALFilterName') : '');
  //const [filterId, setFilterId] = useState(!(localStorage.getItem('memberCALFilterId') === undefined || localStorage.getItem('memberCALFilterId') === null)  ? localStorage.getItem('memberCALFilterId') : '');

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Nastavení pro stránkování
  const itemsPerPage = 25

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    
    // Kontrola přihlášení  
    //const aToken = await getToken()
    if (localStorage.getItem('isLogged') !== 'is') {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      


      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers(localStorage.getItem('email')!, localStorage.getItem('PIN')!)
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5

      await rootStore.fetchMedicalExaminationsCALList(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena

      

    }
    /*if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    */ 
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  const normalizeText = (text:string) => {
    return text !== null ? text
    .normalize('NFD') // Normalizuje text, aby rozložil kombinované znaky
    .replace(/[\u0300-\u036f]/g, '') // Odstraní diakritiku
    .replace(/\s+/g, '-') // Nahradí všechny mezery pomlčkami
    : ''
  }


  // Dotaz před schválením/zamítnutím žádosti
  const handleOnClickAcceptItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete žádost "+text+" schválit?", 'Ano, schválit', 'Ne', {title: 'Schválení žádosti'})) {
        
        accept(id)
      } else {
      
      }  
    })()
  }
  const handleOnClickRejectItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete žádost "+text+" zamítnout?", 'Ano, zamítnout', 'Ne', {title: 'Zamítnutí žádosti'})) {
        
        reject(id)
      } else {
      
      }  
    })()
  }

  const accept = (id:string) => {
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(Constants.URL_MEDICAL_EXAMINATIONS_CAL_ACCEPT, { idMedicalExaminationUploads: id, userId: rootStore.loggedUsers[0]?.idUsers, userPassword: localStorage.getItem('PIN')!}
        ).then(response => {
          
          // Změna dat ve Store
          
          rootStore.fetchMedicalExaminationsCALList(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

        }).catch(error => {

        });
    })()
  }

  const reject = (id:string) => {
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(Constants.URL_MEDICAL_EXAMINATIONS_CAL_REJECT, { idMedicalExaminationUploads: id, userId: rootStore.loggedUsers[0]?.idUsers, userPassword: localStorage.getItem('PIN')!}
        ).then(response => {
          
          // Změna dat ve Store
          
          rootStore.fetchMedicalExaminationsCALList(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

        }).catch(error => {

        });
    })()
  }




  //Počet záznamů
  const countData:number = rootStore.medicalExaminationsCAL
  .filter(data => data.state !== 1)
  .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
  //.filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  })
  .length

    // Seznam zákazníků do řádků tabulky
    const examinationsDataTable = rootStore.medicalExaminationsCAL
    .filter(data => data.state !== 1)
    .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
    //.filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  })
    .slice(indexOfFirstItem, indexOfLastItem)
  
    .map(item => (
      <tr key={item.idMedicalExaminationUploads} style={{backgroundColor: item.state === 2 ? '#ffe6e6' : ''}}>
        <td>{item.idMedicalExaminationUploads}</td>
        <td>{moment(item.uploaded).format("DD.MM.YYYY")}</td>
        <td>{item.idMembers}</td>
        <td>{item.fullName}</td>
        <td>{item.email}</td>
        <td>{moment(item.birthdate).format("DD.MM.YYYY")}{!item.memberBirthDateIsTrue && <>&nbsp;<Badge variant='danger'>Nesouhlasí</Badge></>}</td>
        <td>{moment(item.medicalExaminationTo).format("DD.MM.YYYY")}</td>
        <td>
        <span 
          style={{cursor: 'pointer', textDecoration: 'underline'}} 
          onClick={() => { 
            let part1 = 'ZZ'+item.idMembers+'_';
            let part2 = normalizeText(item.fullName);
            let part3 = '_do_'+moment(item.medicalExaminationTo).format("YYYY-MM-DD")+'_'+item.fileId;
            let newName:string = part1+part2+part3 ;
            //handleDownloadDocument(item.fileId!, newName )
            handleOpenPdf(item.fileId!)  
          }}
          >
            ZZ_{item.idMembers}_{normalizeText(item.fullName)}_do_{moment(item.medicalExaminationTo).format("YYYY-MM-DD")}
          </span>
          </td>
        <td>
          {item.state === 0 ? <Badge variant="warning">Čeká na schválení</Badge> : ''}
          {item.state === 1 ? <Badge variant="success">Schváleno</Badge> : ''}
          {item.state === 2 ? <Badge variant="danger">Zamítnuto</Badge> : ''}
        </td>

        <td>
          <MedicalExaminationsUpdate id={item.idMedicalExaminationUploads} />
          <ButtonGroup>
            <Button size="sm" disabled={item.state === 1} variant="success" onClick={() => { handleOnClickAcceptItem(item.idMedicalExaminationUploads, String(item.fullName)) }}>Schválit</Button>
            <Button size="sm" disabled={item.state === 2} variant="danger" onClick={() => { handleOnClickRejectItem(item.idMedicalExaminationUploads, String(item.fullName)) }}>Zamítnout</Button>
          </ButtonGroup>
        </td>
      </tr>
    ))

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);

        sessionStorage.setItem('examinationsCALListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        setPage(pageNumber)
        //await rootInvoicesStore.fetchInvoicesList(pageNumber, props.invoiceType)
        setLoading(false);  
      })()
    }



    const handleDownloadDocument = async (fileId:string, fileNameToSave:string) => {
      const fileUrl = 'https://easyapp.prihlaskanasoutez.cz/files/medicalExaminations/'+fileId; // URL souboru ke stažení
      const fileName = fileNameToSave; // Nový název souboru
    
      try {
        const response = await fetch(fileUrl, { credentials: 'same-origin' });
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
    
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName; // Nastavení požadovaného názvu souboru
        a.type = 'application/octet-stream';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error('Download error:', error);
      }
      /*
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName; // Nastavení požadovaného názvu souboru
      a.type = 'application/octet-stream';
    
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      */
    }

    const handleOpenPdf = (fileId: string) => {
      const fileUrl = `https://easyapp.prihlaskanasoutez.cz/files/medicalExaminations/${fileId}`;
      window.open(fileUrl, '_blank'); // Otevře PDF v nové záložce
    };



    const schemaUpdate = Yup.object().shape({
  
    })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <h4>Seznam nespárovaných plateb</h4>
                <br />
                <Alert variant="info">
                  <Formik
                    validationSchema={schemaUpdate}
                    initialValues={{ 
                      //memberCALId: !(localStorage.getItem('memberCALFilterId') === undefined || localStorage.getItem('memberCALFilterId') === null)  ? localStorage.getItem('memberCALFilterId') : '',
                      examinationsCALName: !(localStorage.getItem('examinationsCALFilterName') === undefined || localStorage.getItem('examinationsCALFilterName') === null)  ? localStorage.getItem('examinationsCALFilterName') : '',
                      
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(async () => {
                        //handleInsert(values.idLecturers!)
                        await setLoading(true)
                        await localStorage.setItem('examinationsCALFilterName', values.examinationsCALName!)
                        //await localStorage.setItem('memberCALFilterId', values.memberCALId!)

                        await setFilterName(values.examinationsCALName!)
                        //await setFilterId(values.memberCALId!)

                        await sessionStorage.setItem('examinationsCALListSearchPage', String(1))
                
                        await setPage(1)
                        await setLoading(false)

                        setSubmitting(false);
                      }, 100);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                    
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                        
                        

                        <FormGroup controlId="examinationsCALName" style={{paddingLeft: '40px', marginBottom: '0px'}}>
                        <Form.Row>
                        <FormLabel column lg={4}>Hledaný text</FormLabel>
                            <Col>
                              <FormControl 
                                type="text"
                                name="examinationsCALName"
                                value={values.examinationsCALName!}
                                onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                                //isValid={touched.educationName && !errors.educationName} 
                                //isInvalid={!!errors.educationName}
                                placeholder='hledaný text'
                                autoFocus
                              />
                              
                              <Form.Control.Feedback type="invalid">
                                {errors.examinationsCALName}
                              </Form.Control.Feedback>
                            </Col>
                            </Form.Row>
                        </FormGroup> 

                        

                        

                        </Form.Row>
                        
                      </Form>
                    )}
                  </Formik>  
                  
                </Alert>
                <br />
                
                <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head row-slim"></th>
                          <th className="jsw-table-head row-slim">Nahráno</th>
                          <th className="jsw-table-head">ID</th>
                          <th className="jsw-table-head">Příjmení a jméno</th>
                          <th className="jsw-table-head">E-mail</th>
                          <th className="jsw-table-head">Dat.nar.</th>
                          <th className="jsw-table-head">Platnost do</th>
                          <th className="jsw-table-head">Soubor</th>
                          <th className="jsw-table-head row-slim">Stav</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {examinationsDataTable}
                      </tbody>
                    </Table> 

                    <br />   
                    {!loading &&
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={Number(sessionStorage.getItem('examinationsCALListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('examinationsCALListSearchPage')) }
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={countData}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange.bind(1)}
                      /> }

                    {countData === 0 ? <div>Seznam žádostí neobsahuje žádné záznamy.<br /><br /></div> : ''}

                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                  </Col>
                </Row>
                
                

                <br /><br />
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(MedicalExaminationsCAL)
