import { types } from 'mobx-state-tree'
import moment from 'moment'

const Competition = types.model('Competition',{
  idCompetitions: types.optional(types.string, ''),
  date: types.maybeNull(types.string),
  dateTo: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  logo: types.maybeNull(types.string),
  applicationsDate: types.maybeNull(types.string),
  allowSolos: types.maybeNull(types.string),
  allowDuets: types.maybeNull(types.string),
  allowTeams: types.maybeNull(types.string),
  allowProams: types.maybeNull(types.string),
  allowCouples: types.maybeNull(types.string),
  allowPara: types.maybeNull(types.string),
  allowLogout: types.maybeNull(types.string),
  idIsVS: types.maybeNull(types.string),
  cpadu: types.maybeNull(types.string),
  sut: types.maybeNull(types.number),
  allowPromoCode: types.optional(types.boolean,false),
  currency: types.optional(types.string,'Kč'),
  currencyCode: types.optional(types.string,'CZK'),
  currency2: types.optional(types.string,''),
  currencyCode2: types.optional(types.string,''),
  generateQR: types.maybeNull(types.string),
  accountPrefix: types.maybeNull(types.string),
  accountNumber: types.maybeNull(types.string),
  bankCode: types.maybeNull(types.string),
  discount: types.optional(types.number, 0),
  discountToDate: types.maybeNull(types.string),
  firstMinutePriceTo: types.maybeNull(types.string),
})
.views(self => ({
  get accountPrefixString() {
    let accountPrefixString:string = ''
    if (self.accountPrefix !== null) {
      if (self.accountPrefix !== '') {
        accountPrefixString = 'accountPrefix='+self.accountPrefix+'&'
      }
    }
    return accountPrefixString
  }

}))

const CompetitionItems = types.model('CompetitionItems',{
  idCompetitionItems: types.optional(types.string, ''),
  idCompetitions: types.optional(types.string, ''),
  idGroup: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  nameEN: types.maybeNull(types.string),
  name2: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  style: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  allowVariableName: types.maybeNull(types.string),
  hdid: types.maybeNull(types.string),
  selected: types.maybeNull(types.string),
  price: types.optional(types.number, 0),
  price2: types.optional(types.number, 0),
  dancesCount: types.optional(types.number, 0),
  variableName: types.maybeNull(types.string),
  idApplicationItems : types.optional(types.number, 0),
  musicFileId: types.maybeNull(types.string),
  uploadMusic: types.optional(types.boolean, false),
})
.views(self => ({
  get levelNum() {

    return Number.isInteger(Number(self.level)) ? Number(self.level) : 0
    //return Number.isNaN(self.level) === false ? Number(self.level) : 0
  },
  
  get levelNum2() {


    return Number(self.level)
  },

  get getFileNameExt() {

    let fileName = self.musicFileId
    let extension = ''
    if (!(fileName === null || fileName === '')) {
      //let ext = filename.split('.').pop()
      extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();  // získání přípony souboru
    }
    return extension
  },

  get variableNameNormalized() {

    return self.variableName !== null ? self.variableName
    .normalize('NFD') // Normalizuje text, aby rozložil kombinované znaky
    .replace(/[\u0300-\u036f]/g, '') // Odstraní diakritiku
    .replace(/\s+/g, '-') // Nahradí všechny mezery pomlčkami

    : ''
    //.toLowerCase(); // Převod na malá písmena

    
  }

}))

const ApplicationMembers = types.model('ApplicationMembers',{
  idApplicationMembers: types.optional(types.string, ''),
  idMembers: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
})
.views(self => ({
  get name() {
    let nametxt:string = self.firstName + ' ' + self.surName
    return nametxt.trim()
  },

}))

export const Application = types
  .model({
    competition: types.maybeNull(Competition),
    competitionItems: types.array(CompetitionItems),
    applicationMembers: types.array(ApplicationMembers),
    
    idApplications: types.optional(types.string, ''),
    idCompetitions: types.optional(types.string, ''),
    firstName1: types.maybeNull(types.string),
    surName1: types.maybeNull(types.string),
    firstName2: types.maybeNull(types.string),
    surName2: types.maybeNull(types.string),
    firstName3: types.maybeNull(types.string),
    surName3: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    date1: types.maybeNull(types.string),
    date2: types.maybeNull(types.string),
    date3: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    secureCode: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    levelSTT: types.maybeNull(types.string),
    levelLAT: types.maybeNull(types.string),
    levelCAR: types.maybeNull(types.string),
    cpadu: types.optional(types.string,''),
    cpadu2: types.optional(types.string,''),
    registeredSUT1: types.optional(types.number,0),
    registeredSUT2: types.optional(types.number,0),
    startno: types.optional(types.number,0),
    startno2: types.optional(types.string,''),
    promoCode: types.optional(types.string,''),
    idMembers1: types.maybeNull(types.string),
    idMembers2: types.maybeNull(types.string),
    idMembers3: types.maybeNull(types.string),
    lastPaidYear1: types.optional(types.number,0),
    lastPaidYear2: types.optional(types.number,0),
    lastPaidYear3: types.optional(types.number,0),
    medicalExaminationTo1: types.maybeNull(types.string),
    medicalExaminationTo2: types.maybeNull(types.string),
    medicalExaminationTo3: types.maybeNull(types.string),
  })
  .views(self => ({
    get medicalExaminationState1() {
      if (self.medicalExaminationTo1 === null || self.medicalExaminationTo1 === '') return false
      else {
        return new Date(self.medicalExaminationTo1!).setHours(0, 0, 0, 0) >= new Date(self.competition?.date!).setHours(0, 0, 0, 0) ? true:false
      }

    },

    get medicalExaminationState2() {
      if (self.medicalExaminationTo2 === null || self.medicalExaminationTo2 === '') return false
      else {
        return new Date(self.medicalExaminationTo2!).setHours(0, 0, 0, 0) >= new Date(self.competition?.date!).setHours(0, 0, 0, 0) ? true:false
      }

    },

    get medicalExaminationState3() {
      if (self.medicalExaminationTo3 === null || self.medicalExaminationTo3 === '') return false
      else {
        return new Date(self.medicalExaminationTo3!).setHours(0, 0, 0, 0) >= new Date(self.competition?.date!).setHours(0, 0, 0, 0) ? true:false
      }

    },
    
    get startnoToDisplay4digits() {
      return self.startno.toString().padStart(4, '0')
    },
    
    get applicationsAfterTerm() {

      return new Date(self.competition!.applicationsDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
    get proamSttMinLevel() {
      let maxSelected = 0
      if (self.type === 'P') {
      self.competitionItems.filter(ci => ci.style === 'STT' && ci.selected === '1' && !Number.isNaN(ci.level) === true ).map(ci => {
        if (Number(ci.level) > maxSelected) {
          maxSelected = Number(ci.level)

        }
      })
      }
      return maxSelected-1
    },

    get proamSttMaxLevel() {
      let minSelected = 999
      if (self.type === 'P') {
      self.competitionItems.filter(ci => ci.style === 'STT' && ci.selected === '1' && !Number.isNaN(ci.level) === true ).map(ci => {
        if (Number(ci.level) < minSelected) {
          minSelected = Number(ci.level)

        }
      })
      }
      return minSelected+1
    },

    get proamLatMinLevel() {
      let maxSelected = 0
      if (self.type === 'P') {
      self.competitionItems.filter(ci => ci.style === 'LAT' && ci.selected === '1' && !Number.isNaN(ci.level) === true ).map(ci => {
        if (Number(ci.level) > maxSelected) {
          maxSelected = Number(ci.level)

        }
      })
      }
      return maxSelected-1
    },

    get proamLatMaxLevel() {
      let minSelected = 999
      if (self.type === 'P') {
      self.competitionItems.filter(ci => ci.style === 'LAT' && ci.selected === '1' && !Number.isNaN(ci.level) === true ).map(ci => {
        if (Number(ci.level) < minSelected) {
          minSelected = Number(ci.level)

        }
      })
      }
      return minSelected+1
    },

    get price() {
      let price:number = 0
      let dancesCount:number = 0

      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        price += ci.price
      }) 
      
      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        dancesCount += ci.dancesCount
      })  

      if (self.competition!.discount! > 0 && new Date(self.competition!.discountToDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
        price = price - (price * self.competition!.discount! / 100)
      }

      if (self.idCompetitions === '11111') {
        if (new Date(self.competition!.firstMinutePriceTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
          if (dancesCount === 1) price = price + 550
          if (dancesCount === 2) price = price + 1100
          if (dancesCount === 3) price = price + 1650
          if (dancesCount === 4) price = price + 2200
          if (dancesCount === 5) price = price + 2750
          if (dancesCount === 6) price = price + 3125
          if (dancesCount === 7) price = price + 3500
          if (dancesCount === 8) price = price + 3875
          if (dancesCount === 9) price = price + 4250
          if (dancesCount === 10) price = price + 4625
          if (dancesCount > 10) price = price + 4625 + ((dancesCount - 10) * 350) 
        }
        else {
          if (dancesCount === 1) price = price + 625
          if (dancesCount === 2) price = price + 1250
          if (dancesCount === 3) price = price + 1875
          if (dancesCount === 4) price = price + 2500
          if (dancesCount === 5) price = price + 3125
          if (dancesCount === 6) price = price + 3625
          if (dancesCount === 7) price = price + 4125
          if (dancesCount === 8) price = price + 4625
          if (dancesCount === 9) price = price + 5125
          if (dancesCount === 10) price = price + 5625
          if (dancesCount > 10) price = price + 5625 + ((dancesCount - 10) * 450) 
        }
      }  
      

      return price
    },

    get price2() {
      let price2:number = 0
      let dancesCount:number = 0

      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        price2 += ci.price2
      })  

      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        dancesCount += ci.dancesCount
      })  

      if (self.competition!.discount! > 0 && new Date(self.competition!.discountToDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
        price2 = price2 - (price2 * self.competition!.discount! / 100)
      }
      
      if (self.idCompetitions === '11111') {
        if (new Date(self.competition!.firstMinutePriceTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
          if (dancesCount === 1) price2 = price2 + 22
          if (dancesCount === 2) price2 = price2 + 44
          if (dancesCount === 3) price2 = price2 + 66
          if (dancesCount === 4) price2 = price2 + 88
          if (dancesCount === 5) price2 = price2 + 110
          if (dancesCount === 6) price2 = price2 + 125
          if (dancesCount === 7) price2 = price2 + 140
          if (dancesCount === 8) price2 = price2 + 155
          if (dancesCount === 9) price2 = price2 + 170
          if (dancesCount === 10) price2 = price2 + 185
          if (dancesCount > 10) price2 = price2 + 185 + ((dancesCount - 10) * 14) 
        }
        else {
          if (dancesCount === 1) price2 = price2 + 25
          if (dancesCount === 2) price2 = price2 + 50
          if (dancesCount === 3) price2 = price2 + 75
          if (dancesCount === 4) price2 = price2 + 100
          if (dancesCount === 5) price2 = price2 + 125
          if (dancesCount === 6) price2 = price2 + 145
          if (dancesCount === 7) price2 = price2 + 165
          if (dancesCount === 8) price2 = price2 + 185
          if (dancesCount === 9) price2 = price2 + 205
          if (dancesCount === 10) price2 = price2 + 225
          if (dancesCount > 10) price2 = price2 + 225 + ((dancesCount - 10) * 18) 
        }
      }  

      return price2
    },

    get priceBeforeDiscount() {
      let price:number = 0

      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        price += ci.price
      })  

      return price
    },

    get priceBeforeDiscount2() {
      let price2:number = 0

      self.competitionItems.filter(ci => ci.selected === '1').map(ci => {
        price2 += ci.price2
      })  

      return price2
    },

    get priceWarning() {
      let priceW:string = ''

      if (self.competition!.discount! > 0 && new Date(self.competition!.discountToDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
        priceW = 'Celková cena obsahuje slevu ve výši '+ self.competition!.discount! + '% a je platná do ' + moment(self.competition!.discountToDate!).format('DD.MM.yyyy') + '. Po tomtu datu startovné činí ' 
      }
      

      return priceW
    },

    get name1() {
      let name1txt:string = self.firstName1 + ' ' + self.surName1
      return name1txt.trim()
    },

    get name2() {
      let name2txt:string = self.firstName2 + ' ' + self.surName2
      return name2txt.trim()
    },

    get name3() {
      let name3txt:string = self.firstName3 + ' ' + self.surName3
      return name3txt.trim()
    },

    get names() {
      let names:string = ''
      
      let name1txt:string = self.firstName1 + ' ' + self.surName1
      let name2txt:string = self.firstName2 + ' ' + self.surName2
      let name3txt:string = self.firstName3 + ' ' + self.surName3

      let name1trim:string = name1txt.trim()
      let name2trim:string = name2txt.trim()
      let name3trim:string = name3txt.trim()

      names = name1trim

      if (names !== '') {
        if (name2trim !== '') {
          names = names + ' & ' + name2trim
        }
        
      }
      else {
        names = name2trim
      }


      if (names !== '') {
        if (name3trim !== '') {
          names = names + ' & ' + name3trim
        }
        
      }
      else {
        names = name3trim
      }

      return names
    },

    get appMembersOlderAge() {

      let appMembersOlderAge:number = 0

      self.applicationMembers.map(am => {
        let age = moment(self.competition?.date).year() - Number(am.date)
        if (age > appMembersOlderAge) {
          appMembersOlderAge = age
        }
      })

      return appMembersOlderAge
    },

    get appMembersYoungerAge() {

      let appMembersYoungerAge:number = 999

      self.applicationMembers.map(am => {
        let age = moment(self.competition?.date).year() - Number(am.date)
        if (age < appMembersYoungerAge) {
          appMembersYoungerAge = age
        }
      })

      return appMembersYoungerAge
    },

    get appMembersAverageAge() {

      let appMembersAverageAge:number = 0

      let countMembers:number = self.applicationMembers.length
      let sumAge:number = 0

      self.applicationMembers.map(am => {
        let age = moment(self.competition?.date).year() - Number(am.date)
        sumAge += age
      })

      return countMembers !== 0 ? (sumAge / countMembers): 0
    }



  }))
  .actions(self => ({
    

  }))
