import React, { useState } from 'react'
import axios from 'axios'

import { useParams } from 'react-router'
//import { Link } from 'react-router-dom'
import { NavLink, withRouter } from 'react-router-dom'

import HomeInfo from '../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../parts/errorAlert/ErrorAlertWithoutLogout.part'
import {Container,  Form, Col, Button, Alert, Image, Row, Card, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../Constants/Constants'

import { useMount } from '../../helpers/lifecycle-hooks'

import { useStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Recaptcha from 'react-recaptcha'

import Loader from 'react-loader'
import moment from 'moment'

import { ROUTES } from '../../Routes'

import { observer } from 'mobx-react'

function CalStartPage(props:any) {

  const [loaded, setLoaded] = useState(true);

  // Kontrola zda se načetla URL BE
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [loadedId, setLoadedId] = useState('')
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)

      setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })



  return (
    <div className="App">
      <main>
      <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    
                    <div>
                      
                    </div>
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="images/cal-small.png" width={130} className="login-logo" /> Informační systém</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Rozcestník - sportovci</h3>
                      
                    
                      <NavLink  className="nav-link" activeClassName="active" to={ROUTES.registrationCAL.getLink()}>
                        <Button variant="primary" size="lg" block >Registrace nového sportovce</Button>
                      </NavLink>

                      <NavLink  className="nav-link" activeClassName="active" to={ROUTES.memberInfoCAL.getLink()}>
                        <Button variant="primary" size="lg" block >Vyhledání informací o sportovci</Button>
                      </NavLink>

                      <NavLink  className="nav-link" activeClassName="active" to={ROUTES.memberMedicalExaminationLoadCAL.getLink()}>
                        <Button variant="primary" size="lg" block >Nahrávání zdravotních způsobilostí</Button>
                      </NavLink>

                    </div>    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>   
        </Loader>
      </main>
    </div>
  )
}

export default withRouter(observer(CalStartPage))