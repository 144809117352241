import { types } from 'mobx-state-tree'


export const MedicalExaminationsCAL = types
  .model({
    idMedicalExaminationUploads: types.optional(types.string, ''),
    idMembers: types.optional(types.string,''),
    birthdate: types.optional(types.string,''),
    memberBirthDate: types.optional(types.string,''),
    surName: types.optional(types.string,''),
    firstName: types.optional(types.string,''),
    email: types.optional(types.string,''),
    medicalExaminationTo: types.optional(types.string,''),
    club: types.optional(types.string,''),
    country: types.optional(types.string,''),
    fileId: types.optional(types.string,''),
    uploaded: types.optional(types.string,''),

    accepted: types.optional(types.boolean,false),
    rejected: types.optional(types.boolean,false),

  })
  .views(self => ({
    
    get searchString() {
      //eslint-disable-next-line
      let searchString:string = ''

      let name:string = ''
      if (!(self.surName === null || self.surName === '')) name = self.surName! 

      let firstName:string = ''
      if (!(self.firstName === null || self.firstName === '')) firstName = self.firstName! 
      
      let club:string = ''
      if (!(self.club === null || self.club === '')) club = self.club! 

      return searchString = name+' '+firstName+' '+club
    },

    get fullName() {
      return self.surName + ' ' + self.firstName
    },

    get memberBirthDateIsTrue() {
      return self.birthdate === self.memberBirthDate
    },

    get state() {
      let state = 0

      if (self.accepted) state = 1
      if (self.rejected) state = 2

      return state
    },
    get stateText() {
      let state = 'Čeká na schválení'

      if (self.accepted) state = 'Schváleno'
      if (self.rejected) state = 'Zamítnuto'

      return state
    },
    
  }))
  .actions(self => ({
    

  }))
